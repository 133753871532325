<template>
<v-card>
  <v-layout class="pa-3 mx-3" row wrap>
    <v-flex xs12 lg3>
      <span class="subtitle-1">{{ lessonSession.class }}</span>
    </v-flex>
    <v-flex xs12 lg1>
      <v-icon small>person</v-icon>
      <span class="caption grey--text">{{ lessonSession.tutor }}</span>
    </v-flex>
    <v-flex xs12 lg2>
      <v-icon small>location_on</v-icon>
      <span class="caption grey--text">{{ lessonSession.venue_name }}</span>
    </v-flex>
    <v-flex xs12 lg2>
      <v-icon small>schedule</v-icon>
      <span class="caption grey--text">{{ lessonSession.time | moment("YYYY/M/D(dddd) HH:mm")}}</span>
    </v-flex>
    <v-flex xs10 lg3>
      <v-chip
        :color="lessonChipStatus.color"
        text-color="white"
        small
      >
        <span>{{ lessonChipStatus.text }}</span>
        <v-avatar right v-if="lessonSession.status_code === 1 && lessonSession.lastestAutoConfirmTime === null"><v-icon small>check_circle</v-icon></v-avatar>
      </v-chip>
    </v-flex>
    <v-flex xs2 lg1 v-if="lessonSession.status_code != LessonStatusLookup.SCHEDULED">
      <div class="d-flex justify-end">
        <div class="caption grey--text">{{lessonSession.lessonEnrollmentInfo.length}}人</div>
        <v-btn x-small icon @click="lessonSession.show = !lessonSession.show">
          <v-icon x-small>{{ lessonSession.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
        </v-btn>
      </div>
    </v-flex>
    <v-flex xs10 lg1>
      <v-btn
        color="red"
        class="ma-2"
        rounded
        outlined
        small
        @click="deleteLesson"
        :disabled="lessonSession.status_code === LessonStatusLookup.ACCOMPLISHED"
        icon
      >
        <v-icon small>delete</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>

  <!-- 签到具体情况 -->
  <v-slide-y-transition>
    <v-card text>
      <v-card-text v-show="lessonSession.show" class="grey--text">
        <span v-for="(lessonSessionEnrollment, enrollmentIndex) in lessonSession.lessonEnrollmentInfo" v-bind:key="`enrollment-${enrollmentIndex}`">
          <v-chip
            :color="`${lessonSessionEnrollment.attendance_code == 2 ? 'green lighten-3': 'red lighten-2'}`"
            text-color="white"
            class="ma-1"
            small
          >
            <span class="caption">{{ lessonSessionEnrollment.student }}[{{ lessonSessionEnrollment.attendance_status }}]</span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar v-if="lessonSessionEnrollment.confirmation_code === 0" right>
                  <v-icon small v-on="on"> hourglass_empty </v-icon>
                </v-avatar>
              </template>
              <span> 等待学员确认 </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar v-if="lessonSessionEnrollment.confirmation_code === 1" right>
                  <v-icon small v-on="on"> check_circle </v-icon>
                </v-avatar>
              </template>
              <span> 状态已确认 </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-avatar v-if="lessonSessionEnrollment.confirmation_code === 2" right>
                  <v-icon small v-on="on"> error </v-icon>
                </v-avatar>
              </template>
              <span> 状态未得到确认，请协商或修改 </span>
            </v-tooltip>
          </v-chip>
        </span>
      </v-card-text>
    </v-card>
  </v-slide-y-transition>
</v-card>
</template>

<script>
import LessonService from '../services/LessonService'
import { LessonStatusLookup } from '../enums'
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    lessonSession: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      classes: [],
      LessonStatusLookup
    }
  },
  computed: {
    lessonChipStatus () {
      let color = 'orange'
      const hasAtLeaseOneUnCheckInStudent = this.lessonSession.lessonEnrollmentInfo.some(obj => obj.attendance_code === 1)
      if (this.lessonSession.status_code === LessonStatusLookup.ACCOMPLISHED) color = 'green' // 已完成结课
      else if (this.lessonSession.status_code === LessonStatusLookup.SCHEDULED || hasAtLeaseOneUnCheckInStudent) color = 'grey' // 仅排课 或 至少有一名未签到学员
      else if (this.lessonSession.lastestAutoConfirmTime === null) color = 'light-green' // 已可以结课

      let text = this.lessonSession.status
      if (this.lessonSession.status_code === LessonStatusLookup.INSTRUCTED && this.lessonSession.lastestAutoConfirmTime && !hasAtLeaseOneUnCheckInStudent) {
        text += `-自动确认: ${this.lessonSession.lastestAutoConfirmTime}`
      } else if (hasAtLeaseOneUnCheckInStudent) {
        text += '-未完成签到'
      } else if (this.lessonSession.status_code === 1 && this.lessonSession.lastestAutoConfirmTime === null) {
        text += '-可结课'
      }
      return { color, text }
    }
  },
  async mounted () {
  },
  filters: {
  },
  methods: {
    async deleteLesson () {
      await LessonService.deleteLesson(this.lessonSession.id)
      console.log(`lesson(id:${this.lessonSession.id}) has been deleted!`)
      this.$emit('sessionDelete')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
<v-card
  :class="`my-5 lesson-template template-overall-status-code-${lessonTemplate.lessonTemplateOverallStatusCode}`"
>
  <v-layout column>

    <!-- 模版信息 -->
    <div class="px-5 pt-5">
      <v-flex>
        <div class="caption grey--text">{{ lessonTemplate.program_title }}</div>
      </v-flex>
      <v-flex>
        <div class="title"> <span v-if="hasContent">{{ lessonTemplate.title }}</span><span v-else class="grey--text text--lighten-2">仅排课，但未创建</span>
          <v-btn
            color="grey"
            outlined
            rounded
            small
            class="mx-3"
            @click="$router.push({
              name: 'edit-lesson-template',
              params: {
                lessonTemplateId: lessonTemplate.template_id
              }
            })"
            :disabled="afterTemplateStartDate < -1"
          >
          <!-- 此句可以在上课前disable按钮 :disabled="afterTemplateStartDate < 1" -->
            {{hasContent ? '编辑内容' : '创建课程'}}
            <v-icon small>edit</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <!-- <div class="">
            <span class="caption"><v-icon small>person</v-icon> {{ lessonTemplate.tutor }}</span>
      </div> -->
      <div class="py-2"></div>
      <v-flex>
        <v-btn
          color="success"
          :disabled="!hasContent || lessonTemplate.lessonTemplateOverallStatusCode === 2"
          outlined
          rounded
          small
          @click="lessonEnrollment(lessonTemplate.program_id, lessonTemplate.template_id)"
        >
          签到
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn
          @click="deleteLessonTemplate"
          :disabled="atLeastOneSession"
          outlined
          icon
          rounded
          small
          color="red"
          class="mx-2"
        >
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-flex>
    </div>
    <v-divider class="mt-3"></v-divider>

    <!-- 具体场次信息 -->
    <v-flex v-if='lessonTemplate.lessonSessions.length' class="d-flex justify-center" @click="lessonTemplate.show = !lessonTemplate.show">
      <v-btn text block small tile>
        <span class="caption grey--text">{{lessonTemplate.show ? '收起' : '展开'}}全部{{lessonTemplate.lessonSessions.length}}场次</span>
        <v-icon small>{{ lessonTemplate.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </v-btn>
    </v-flex>
    <v-flex>
      <v-slide-y-transition>
        <v-card-text v-show="lessonTemplate.show" class="grey--text">

          <v-divider></v-divider>

          <div class="mb-3">
            <div v-for="(lessonSession, lessonSessionIndex) in lessonTemplate.lessonSessions" v-bind:key="`lesson-session-${lessonSessionIndex}`">
              <y-session
                :lessonSession=lessonSession
                @sessionDelete="removeSession(lessonSessionIndex)"
              />
            </div>
          </div>

          <!-- 详细信息 -->
          <div class="mb-3" v-if="lessonTemplate.description">
            <v-subheader>活动详情
              <v-btn
                text
                icon
                color="grey"
                @click="$router.push({
                  name: 'edit-lesson-template',
                  params: {
                    lessonTemplateId: lessonTemplate.template_id
                  }
                })"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </v-subheader>
            {{ lessonTemplate.description | truncate(60) }}
          </div>
        </v-card-text>
      </v-slide-y-transition>
    </v-flex>
  </v-layout>
</v-card>
</template>

<script>
import YSession from '@/components/YSession'
import LessonService from '../services/LessonService'
import { compareAsc } from 'date-fns'
export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    lessonTemplate: {
      type: Object,
      default: null
    }
  },
  components: {
    YSession
  },
  data () {
    return {
      classes: []
    }
  },
  computed: {
    atLeastOneSession () {
      if (this.lessonTemplate.lessonSessions.length > 0) {
        return true
      } else {
        return false
      }
    },
    hasContent () {
      return (this.lessonTemplate.program_title && this.lessonTemplate.description)
    },
    afterTemplateStartDate () {
      const now = new Date()
      const firstLessonTime = new Date(this.lessonTemplate.startDate)
      return compareAsc(now, firstLessonTime)
    }
  },
  async mounted () {
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...'
      }
      return value
    }
  },
  methods: {
    async lessonEnrollment (programId, lessonTemplateId) {
      // prepare classes
      const programClasses = this.classes.filter(function (el) {
        return el.program_id === programId
      })
      console.log('classes under current program are filtered to be: ', programClasses)

      // route
      this.$router.push({
        name: 'lesson-enrollment',
        params: {
          programId: programId,
          lessonTemplateId: lessonTemplateId,
          classes: programClasses
        }
      })
    },
    removeSession (index) { // only remove in memory
      this.lessonTemplate.lessonSessions.splice(index, 1)
    },
    async deleteLessonTemplate () {
      console.log(`deleteLessonTemplate starts ... `)
      await LessonService.deleteLessonTemplate(this.lessonTemplate.template_id)
      console.log('successful! returning to lesson templates list ... ')
      this.$emit('templateDelete')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .lesson-template.template-overall-status-code-2 {
    border-left: 4px solid #3cd1c3;
  }
  .lesson-template.template-overall-status-code-1 {
    border-left: 4px solid orange;
  }
  .lesson-template.template-overall-status-code-0 {
    border-left: 4px solid grey;
  }
</style>
